import React, {Component} from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import {debounce} from 'lodash';
import {filterStocks} from 'trade-redux/stocks/stocksActions';
import StocksFilterSort from '../../components/StocksFilterSort';

export class StocksFilter extends Component {

    static propTypes = {
        filter: propTypes.object,
        filterStocks: propTypes.func
    }

    constructor(props) {
        super(props);

        this.onFilerFieldChange = this.onFilerFieldChange.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }

    onFilter = debounce((value, name) => {
        this.props.filterStocks(Object.assign({}, this.props.filter, {[name]: value}));
    }, 250)

    onFilerFieldChange(value, name){
        this.onFilter(value, name);
    }
    
    render(){
        return (
            <StocksFilterSort filter={this.props.filter} onFilterChange={this.onFilerFieldChange} />
        )
    }
}

  const mapStateToProps = state => {
    return {
        filter: state.stocks.filter,
    };
  };
  
const mapDispatchToProps = {filterStocks};

export default connect(mapStateToProps, mapDispatchToProps)(StocksFilter);