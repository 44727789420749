import React, { Component } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import trade from 'trade';
import Stocks from './containers/Stocks';


const store = createStore(rootReducer, applyMiddleware(thunk));

class App extends Component {
  
  constructor(props){
    super(props)

    this.state = {
      stocks: [],
      filteredStocks: [],
    }

    this.onStocksChange = this.onStocksChange.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  async componentDidMount(){
  	this.refreshData()
  }

  async refreshData(){
  	// const mock = await getMockedStocks();
  	// const stocks = new trade.StocksArray(...Object.values(mock)).withoutEmpty()
  	const stocks = await trade.stocks.getStocks()
    this.setState({stocks, filteredStocks:stocks})
  }

  onStocksChange(stocks, filter){
    this.setState({filteredStocks: stocks})
  }

  render() {
    return (
      <Provider store={store}>
        <Stocks /> 
      </Provider>
    );
  }
}

export default App;
