import React, {Component} from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import {getStocks} from 'trade-redux/stocks/stocksActions';
import {getFilteredStocks} from 'trade-redux/stocks/stocksSelectors';
import StocksFilter from '../StocksFilter';
import StocksList from '../../components/StocksList';
import { Spin, Button } from 'antd';
import { copyToClipboard } from 'utils';


export class Stocks extends Component {

    static propTypes = {
        stocks: propTypes.array,
        getStocks: propTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {};
        
        this.props.getStocks();
    }

    getSymbols = () => {
        const symbols = this.props.stocks.map(stock => stock.quote.symbol).join(',');
        copyToClipboard(symbols);
    }

    handlePopoverVisibleChange = (popover, visible) => {
        this.setState({ [`${popover}Visible`]: visible });
    }

    render (){
        return (
            <div className="Stocks">
                <StocksFilter />
            <div className="stocks-actions">
                Stocks count: {this.props.stocks.length}
                <Button size={'small'} onClick={this.getSymbols}>Copy Symbols</Button>
            </div>
                
                { this.props.stocksLoading ? <div className="spin-Wrap"><Spin tip="Fetching stocks..." /></div> : <StocksList stocks={this.props.stocks} /> }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {filter, isLoading, errorMsg} = state.stocks;

    return {
        filter,
        stocks: getFilteredStocks(state),
        stocksLoading: isLoading,
        errorMsg,
    };
  };
  
const mapDispatchToProps = {getStocks};

export default connect(mapStateToProps, mapDispatchToProps)(Stocks);