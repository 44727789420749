/**
This function will delay network calls to a defined rate.
@return {Function} function that delays function call execution. 
									 The returned function returns a promise that resolves 
									 when the network call resolves.
*/
export function limitRate(func, interval) {
  var queue = [];
  var intervalID = setInterval(function () {
    if (queue.length) {
      var action = queue.shift();
      var f = action[0];
      var args = action[1];
      var cb = action[2];
      f.apply(null, args).then(function (success) {
        return cb(success);
      }, function (error) {
        return cb(null, error);
      });
    } else {
      clearInterval(interval);
    }
  }, interval);
  return function (args) {
    return new Promise(function (resolve, reject) {
      queue.push([func, args, function (success, error) {
        if (success) resolve(success);else reject(error);
      }]);
    });
  };
}
export default limitRate;