import 'react-virtualized/styles.css'

import React, { Component } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import { Column, Table, AutoSizer, SortDirection } from 'react-virtualized';
import FormattedNumber from '../FormattedNumber';
import FormattedDate from '../FormattedDate';

export default class StocksList extends Component {
  static propTypes = {
    stocks: propTypes.array
  }

  static defaultProps = {
    stocks: []
  }

  constructor(props){
    super(props)

    this.state = {
      stocks: this.updateStocks(props.stocks),
      sortBy: 'symbol', 
      sortDirection: SortDirection.ASC,
    }

    this.onSort = this.onSort.bind(this);
  }

  componentWillReceiveProps(newProps){
    const stocks = this.updateStocks(newProps.stocks)
    this.setState({stocks})
  }

  updateStocks(stocks){
    return stocks.map(this.simpleStock);
  }

  simpleStock(stock){
    const newestNewsTime = stock.news && stock.news.length ? stock.news[0].datetime : 0;
    return {
      symbol: stock.quote.symbol,
      price: parseFloat(stock.quote.latestPrice),
      volume: parseInt((stock.quote.iexVolume) || 0),
      avgVolume: parseInt((stock.quote.avgTotalVolume) || 0),
      float: parseInt((stock.stats.float) || 0),
      percChange: parseFloat((stock.quote.changePercent * 100).toFixed(2)),
      time: moment(newestNewsTime)
    }
  }

  cellDataGetter({dataKey,rowData}) {
    return get(rowData, dataKey);
  }

  colorCodedCellRenderer({cellData}){
    let className;
    let indicator;

    if(cellData > 0) {
      className = 'positive';
      indicator = '+';
    }

    if (cellData < 0) {
      className = 'negative';
    }
    return <span className={className}>{indicator}{cellData}</span>
  }

  onSort({sortBy, sortDirection}) {
    const {stocks} = this.state;
    const filteredStocks = [].concat(stocks).sort(this.getSortingFunction(sortBy, sortDirection))
    this.setState({stocks: filteredStocks, sortBy, sortDirection})
  }

  getSortingFunction(sortBy, sortDirection){

    let fn = () => {};
    
    switch(sortBy) {
      
      case 'time':
        fn = (a, b) => a[sortBy] < b[sortBy] ? 1 : -1;
        break;

      default:
        fn = (a, b) => a[sortBy] > b[sortBy] ? 1 : -1;
        break;
    }

    return (a, b) => {
      const asc = fn(a, b);
      return sortDirection === SortDirection.ASC ? asc : asc * -1;
    }
  }

  renderNumericCell({cellData}){
    return <FormattedNumber num={cellData} />
  }

  renderDateCell({cellData}){
    return <FormattedDate date={cellData} />
  }

  renderSymbolCell({cellData}){
    return <a href={`https://finance.yahoo.com/quote/ICLK?p=${cellData}`} target="_blank">{cellData}</a>
  }

  render() {
    const {stocks, sortBy, sortDirection} = this.state;
    
    return (
      <div className="StocksList">
        <AutoSizer>
          {({width, height}) => (
            <Table width={width}
                   height={height}
                   headerHeight={20}
                   rowHeight={30}
                   rowCount={stocks.length}
                   rowGetter={({ index }) => stocks[index]}
                   overscanRowCount={10}
                   sort={this.onSort}
                   sortBy={sortBy}
                   sortDirection={sortDirection} >
              <Column cellRenderer={this.renderSymbolCell} label='Symbol' dataKey='symbol' width={100} />
              <Column label='Price' dataKey='price' width={200} />
              <Column cellRenderer={this.colorCodedCellRenderer} label='Percent Change' dataKey='percChange' width={200} className="uppercase"/>
              <Column cellRenderer={this.renderNumericCell} label='Volume' dataKey='volume' width={200} className="uppercase" />
              <Column cellRenderer={this.renderNumericCell} label='Avg Volume' dataKey='avgVolume' width={200} className="uppercase" />
              <Column cellRenderer={this.renderNumericCell} label='Float' dataKey='float' width={200} className="uppercase" />
              <Column cellRenderer={this.renderDateCell} label='Latest News' dataKey='time' width={200} />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
}
