import { createSelector } from 'reselect';

var getFilter = function getFilter(state) {
  return state ? state.stocks.filter : {};
};

var getStocks = function getStocks(state) {
  return state ? state.stocks.stocks : [];
};

export var getFilteredStocks = createSelector([getStocks, getFilter], function (stocks, filter) {
  var minPrice = filter.minPrice,
      maxPrice = filter.maxPrice,
      newsRecency = filter.newsRecency,
      percChange = filter.percChange;
  return stocks && stocks.withPrice ? stocks.withPrice(parseInt(maxPrice), parseInt(minPrice)).withRecentNews(newsRecency).withChangePercent(percChange) : [];
});