import _refData from './ref-data';
import _stocks from './stocks';
import _limitRate from './limitRate';
export var refData = _refData;
export var stocks = _stocks;
export var limitRate = _limitRate;
export default {
  refData: refData,
  stocks: stocks,
  limitRate: limitRate
};