import React, { Component } from 'react';
import propTypes from 'prop-types';
import {noop} from 'lodash';
import { Form, DatePicker, InputNumber } from 'antd';


class StocksFilterSort extends Component {
  static propTypes = {
    filter: propTypes.object,
    onFilterChange: propTypes.func,
  }

  static defaultProps = {
    filter: {},
    onFilterChange: noop,
  }

  constructor(props){
    super(props);

    this.onFilterChange = this.onFilterChange.bind(this);
  }

  onFilterChange(value, name){
    this.props.onFilterChange(name, value);
  }

  render() {
    const {Item} = Form;

    return (
      <Form className="StocksFilterSort" layout="inline">

        <Item label="Min Price">
          <InputNumber placeholder="10.00" 
                       min={0}
                       onChange={this.onFilterChange.bind(this, 'minPrice')} 
                       value={this.props.filter.minPrice}/>
        </Item>

        <Item label="Max Price">
          <InputNumber placeholder="1.00" 
                       min={0}
                       onChange={this.onFilterChange.bind(this, 'maxPrice')} 
                       value={this.props.filter.maxPrice}/>
        </Item>

        <Item label="% Change">
          <InputNumber placeholder="10.00" 
                       onChange={this.onFilterChange.bind(this, 'percChange')} 
                       value={this.props.filter.percChange}/>
        </Item>

        <Item label="Recent News">
          <DatePicker showTime 
                      format="YYYY-MM-DD HH:mm:ss" 
                      onChange={this.onFilterChange.bind(this, 'newsRecency')}
                      value={this.props.filter.newsRecency} />
        </Item>
      </Form>
    );
  }
}

export default StocksFilterSort;
