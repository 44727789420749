import _objectSpread from "/opt/build/repo/app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { GET_STOCKS_START, GET_STOCKS_SUCCESS, GET_STOCKS_FAIL, STOCKS_FILTER_CHANGE } from './stocksActionTypes';
var initialState = {
  stocks: [],
  filter: {
    minPrice: '',
    maxPrice: ''
  },
  isLoading: false,
  errorMsg: ''
};
export default function stocksReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_STOCKS_START:
      return _objectSpread({}, state, {
        isLoading: true,
        errorMsg: ''
      });

    case GET_STOCKS_SUCCESS:
      return _objectSpread({}, state, {
        isLoading: false,
        stocks: action.payload.stocks
      });

    case GET_STOCKS_FAIL:
      return _objectSpread({}, state, {
        isLoading: false,
        errorMsg: action.payload.error.message
      });

    case STOCKS_FILTER_CHANGE:
      return _objectSpread({}, state, {
        filter: action.payload.filter
      });

    default:
      return state;
  }
}